<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>Enter OTP</strong>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert v-if="err_msg != ''" :color="messageColor" closeButton :show.sync="dismissCountDown" fade>{{ err_msg }}
          </CAlert>
          <CForm novalidate @submit.prevent="onSubmit">
            <CCardBody>
              <div class="form-group">
                <label class="form__label">OTP
                  <required_span />
                </label>
                <input placeholder="Enter OTP" class="form-control" v-model="otp" />
              </div>
              <small><b>Note: You will receive OTP on your email address. OTP will be valid for 15 minutes.</b></small>
              <div style="float:right;">
                <CButton color="primary" size="sm" v-on:click="sendOtp()">Send OTP</CButton>
                <br>
              </div>
            </CCardBody>
            <CCardFooter>
              <CButton type="submit" size="sm" color="primary" :disabled="submitted">
                <CIcon name="cil-check-circle" />
                {{ $lang.buttons.general.crud.submit }}
              </CButton>
            </CCardFooter>
          </CForm>
         
        
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import store from "../../store/store";
import required_span from "../../components/layouts/general/required-span";

Vue.use(VueForm, options);

export default {
  name: "FeedbackView",
  mixins: [Mixin],
  components: {
    required_span
  },
  data() {
    return {
      err_msg: "",
      otpVerified: false,
      submitted: false,
      otp: "",
      currentId: "",
      messageColor: "success",
      dismissCountDown: 0,
      dismissCountDownS: 0,
      role : localStorage.getItem("roleName"),
    };
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false);
    self.currentId = this.$route.params.id;

  },
  methods: {
    sendOtp() {
      let self = this;
      axios
        .post('/generate-staff-otp', {})
        .then(function (response) {
          if (response.data.code === 200) {
            self.err_msg = response.data.data.success;
            self.messageColor = "success";
            self.dismissCountDownS = 10;
          } else {
            self.messageColor = "danger";
            self.err_msg = response.data.message;
            self.dismissCountDown = 10;
          }
        })
        .catch(function (error) {
          let data;
          if (error.response.data.error) {
            data = error.response.data.error.toString();
          } else {
            data = error.response.data.message;
          }
          self.err_msg = data;
          self.dismissCountDown = 10;
          self.submitted = false; //Enable Button
          self.messageColor = "danger";

        });
    },
    onSubmit() {
      let self = this;

      self.submitted = true; //Disable Button
      setTimeout(() => {
        let self = this;
        const postData = {
          otp: self.otp,
        };
        axios
          .post('/verify-staff-otp', postData)
          .then(function (response) {
            self.submitted = false; //Enable Button

            if (response.data.code === 200) {
              self.otpVerified = true;
              self.largeModal = false;
              self.err_msg = response.data.data.success;
              self.messageColor = "success";
              self.dismissCountDownS = 10;
              self.$router.push({ path: '/profile/view/'+self.currentId })

            } else {
              self.messageColor = "danger";
              self.err_msg = response.data.message;
              self.dismissCountDown = 10;
            }
          })
          .catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.err_msg = data;
            self.dismissCountDown = 10;
            self.submitted = false; //Enable Button
            self.messageColor = "danger";

          });
      }, 500);
    },
  },
};
</script>
